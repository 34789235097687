import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrap = styled.div`
  ${tw`bg-grayu-semimed pt-20 sm:pt-40 flex flex-col items-center  h-screen text-center`};
  div{
    ${tw`w-full sm:w-544 bg-white p-p32`};
  }
  p{
    ${tw`text-brown-semidark pb-p32 tracking-ls-4 text-ft-16 font-bold`};
  }
  button{
    ${tw`w-full bg-blacku-default text-white leading-lh-45 tracking-ls-5`};
  }
`;