import React from "react"
import 'assets/styles/global.css';
import SEO from "../components/SEO"
import Restriction from "../components/Restriction"
import { I18nextContext } from "gatsby-plugin-react-i18next";

const RestrictionPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);
  return (
    <div>
      <SEO title="Restriction" />
      <Restriction locale={language}/>
    </div>
  )
}

export default RestrictionPage
