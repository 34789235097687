import React from 'react';
import * as Styled from './styles';
import PropTypes from "prop-types";

const Restriction = ({ locale }) => {
  return (
    <Styled.Wrap>
      <div>
        <p>{locale === 'en' ? 'You must be 18+ to access our store. Your access has been restricted' : '您必须年满 18 岁才能访问我们的商店。您的访问已被限制'}</p>
      </div>
    </Styled.Wrap>
  );
};

Restriction.propTypes = {
  token: PropTypes.any,
  locale: PropTypes.string,
};

Restriction.defaultProps = {
  locale: 'en',
};

export default Restriction;
